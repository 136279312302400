import React, { type FC } from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl-next';
import { styled } from '@compiled/react';

import EditorAddIcon from '@atlaskit/icon/glyph/editor/add';
import AddIcon from '@atlaskit/icon/utility/add';
import { token } from '@atlaskit/tokens';
import { B400, N500, N700 } from '@atlaskit/theme/colors';
import type { TriggerProps } from '@atlaskit/popup';
import Button from '@atlaskit/button';

import { useIsNav4Enabled } from '@confluence/nav4-enabled';

const i18n = defineMessages({
	createLabel: {
		id: 'create-blank-fabric-page.tree-create-content-button.create-label',
		description: 'Label for a button in the page tree that creates content in the current space',
		defaultMessage: 'Create',
	},
	createAriaLabelForSpace: {
		id: 'create-blank-fabric-page.tree-create-content-button.create-aria-label-for-space',
		description:
			'Aria label for a button in the page tree that creates content in the current space',
		defaultMessage: 'Create content in this space',
	},
});

type TreeCreateContentButtonProps = {
	triggerProps?: TriggerProps;
	onClick: (event?: React.MouseEvent<HTMLElement>) => void;
	isSelected?: boolean;
	href?: string;
};

export const TreeCreateContentButton: FC<TreeCreateContentButtonProps> = ({
	triggerProps,
	onClick,
	isSelected,
	href,
}) => {
	const intl = useIntl();
	const isNav4Enabled = useIsNav4Enabled();

	const icon = (
		<IconWrapper isSelected={isSelected} isNav4Enabled={isNav4Enabled}>
			{isNav4Enabled ? <AddIcon label="" color="currentColor" /> : <EditorAddIcon label="" />}
		</IconWrapper>
	);

	return (
		<Button
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			style={{
				height: isNav4Enabled ? '32px' : '36px',
				paddingLeft: isNav4Enabled ? '0px' : '10px',
				marginLeft: isNav4Enabled ? '4px' : '0px',
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop
				alignItems: 'center',
			}}
			href={href}
			onClick={onClick}
			isSelected={isSelected}
			testId="tree-create-content-button"
			aria-label={intl.formatMessage(i18n.createAriaLabelForSpace)}
			role="button"
			iconBefore={icon}
			appearance="subtle"
			shouldFitContainer
			{...triggerProps}
		>
			<TitleWrapper isSelected={isSelected} isNav4Enabled={isNav4Enabled}>
				<FormattedMessage {...i18n.createLabel} />
			</TitleWrapper>
		</Button>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const IconWrapper = styled.span<{
	isSelected?: boolean;
	isNav4Enabled: boolean;
}>(
	{ color: token('color.icon.subtle', N700) },
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isSelected }) => isSelected && { color: token('color.icon.selected', B400) },
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	({ isNav4Enabled }) =>
		isNav4Enabled && {
			display: 'flex',
			height: '24px',
			width: '24px',
			justifyContent: 'center',
			alignItems: 'center',
			marginLeft: token('space.025', '2px'),
		},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const TitleWrapper = styled.span<{ isSelected?: boolean; isNav4Enabled: boolean }>(
	{
		display: 'flex',
		color: token('color.text.subtle', N500),
		fontWeight: 400,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
		marginLeft: ({ isNav4Enabled }) =>
			isNav4Enabled ? token('space.025', '2px') : token('space.050', '4px'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isSelected }) =>
		isSelected && {
			color: token('color.text.selected', B400),
			fontWeight: 600,
		},
);
