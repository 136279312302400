import React, { useState, useCallback, forwardRef } from 'react';
import type { FC } from 'react';
import { styled } from '@compiled/react';
import { Subscribe } from 'unstated';
import FeatureGates from '@atlaskit/feature-gate-js-client';

import type { PopupComponentProps } from '@atlaskit/popup';
import Popup from '@atlaskit/popup';
import type { Placement } from '@atlaskit/popper';
import Blanket from '@atlaskit/blanket';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { Box, xcss } from '@atlaskit/primitives';

import { START } from '@confluence/navdex';
import { useHardStorageEnforcement } from '@confluence/storage-enforcement/entry-points/HardEnforcement/useHardStorageEnforcement';
import { useIsEditorPage } from '@confluence/route-manager/entry-points/useIsEditorPage';
import { PageTreeStateUpdater } from '@confluence/page-tree-refresh-state-container';
import { useSSRPlaceholderReplaceIdProp } from '@confluence/loadable';
import { useIsEligibleForSurfaceImport } from '@confluence/import-from-sources';

import { useSpaceEnabledContentTypes } from '../useSpaceEnabledContentTypes';
import { usePreloadCreationMenuData } from '../usePreloadCreationMenuData';

import { ContextualCreateContentButton } from './ContextualCreateContentButton';
import { TreeCreateContentButton } from './TreeCreateContentButton';
import { ContextualCreateContentPopoverMenuLoader } from './ContextualCreateContentPopoverMenuLoader';

type ContextualCreatePopoverProps = {
	closeAllHoverPageCards?: () => void;
	isContextualCreateFocused?: boolean;
	setIsContextualCreateFocused?: (isContextualCreateFocused: boolean) => void;
	spaceKey: string;
	parentId?: string;
	source: string;
	displayTitle?: string;
	rootCreateButtonStyling?: boolean;
	// Onboarding experiment prop to display the popup despite a rerender due to state change
	shouldShowPopup?: boolean;
	setShouldShowPopup?: (shouldShowPopup: boolean) => void;
	isTreeItem?: boolean;
	placement?: Placement;
	offset?: [number, number];
	onItemClick?: () => void;
	shouldRenderToParent?: boolean;
	hideFolder?: boolean;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TriggerContainer = styled.div({
	cursor: 'default',
});

const customPopupContainerStyles = xcss({
	zIndex: 'modal',
	backgroundColor: 'elevation.surface.overlay',
	boxShadow: 'elevation.shadow.overlay',
	borderRadius: '3px',
	':focus': {
		outline: 'none',
	},
});

const CustomPopupContainer = forwardRef<HTMLDivElement, PopupComponentProps>(
	({ children, 'data-testid': dataTestId, ...props }, popupRef) => (
		<Box testId={dataTestId} {...props} ref={popupRef} xcss={customPopupContainerStyles}>
			{children}
		</Box>
	),
);

export const ContextualCreateContentPopover: FC<ContextualCreatePopoverProps> = ({
	closeAllHoverPageCards,
	isContextualCreateFocused,
	setIsContextualCreateFocused,
	spaceKey,
	parentId,
	source,
	displayTitle,
	rootCreateButtonStyling = false,
	shouldShowPopup,
	setShouldShowPopup,
	isTreeItem,
	placement = 'right-start',
	offset = [-10, 8],
	onItemClick,
	shouldRenderToParent,
	hideFolder,
}) => {
	const ssrPlaceholderIdProp = useSSRPlaceholderReplaceIdProp();
	const [isOpen, setIsOpen] = useState<boolean>(() => !!shouldShowPopup);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	// Storage Enforcement - #cc-onboarding
	const { enforceStorageLimit } = useHardStorageEnforcement({
		source: 'page-tree-contextual-create',
	});
	// Surface imports - #cconboarding
	const { isEligibleForSurfaceImport, isSurfaceImportExperimentCohort } =
		useIsEligibleForSurfaceImport();

	const isOnEditRoute = useIsEditorPage();
	const preloadCreationMenuData = usePreloadCreationMenuData();

	const preloadCreationMenu = () => {
		preloadCreationMenuData();
		void ContextualCreateContentPopoverMenuLoader.preload();
	};

	const stopEvents = useCallback((event: any) => {
		event.stopPropagation();
		event.preventDefault();
	}, []);

	const onTriggerClick = useCallback(() => {
		setIsContextualCreateFocused?.(!isContextualCreateFocused);
		closeAllHoverPageCards?.();
		setIsOpen(!isOpen);
		setShouldShowPopup?.(!isOpen);
		isEligibleForSurfaceImport &&
			FeatureGates.manuallyLogExperimentExposure('confluence_surface_import');

		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'contextualCreateButton',
				actionSubjectId: source,
				source: 'containerNavigation',
				attributes: {
					navdexPointType: START,
					isOnEditRoute,
				},
			},
		}).fire();
	}, [
		isOpen,
		setIsOpen,
		setShouldShowPopup,
		closeAllHoverPageCards,
		isContextualCreateFocused,
		setIsContextualCreateFocused,
		createAnalyticsEvent,
		source,
		isOnEditRoute,
		isEligibleForSurfaceImport,
	]);

	const onCloseHandler = useCallback(() => {
		// The bluring of the active element should be temporary, see this ticket for an explanation: https://hello.atlassian.net/browse/CCT-906
		const activeEle = document.activeElement;
		// @ts-ignore
		activeEle?.blur();
		setIsOpen(false);
		setShouldShowPopup?.(false);
		setIsContextualCreateFocused?.(false);
	}, [setIsOpen, setShouldShowPopup, setIsContextualCreateFocused]);

	const {
		isCreateWhiteboardEnabled,
		isCreateDatabaseEnabled,
		isCreateSmartLinkEnabled,
		isLivePagesEnabled,
		isCreateFolderEnabled,
	} = useSpaceEnabledContentTypes(spaceKey);

	return (
		<Subscribe to={[PageTreeStateUpdater]}>
			{(pageStateUpdater: PageTreeStateUpdater) => (
				// rendering popup to parent for a11y purposes was causing issues for contextual create on tree items in the page tree
				// so popup is rendered to parent based on whether shouldRenderToParent prop is true
				// TODO: DISCO-2052 resolve this issue and render to parent for all cases
				<>
					{shouldRenderToParent && isOpen && (
						<Blanket
							testId="contextual-create-blanket"
							isTinted={false}
							onBlanketClicked={onCloseHandler}
						/>
					)}
					<Popup
						isOpen={isOpen}
						onClose={onCloseHandler}
						placement={placement}
						offset={offset}
						content={() => (
							<ContextualCreateContentPopoverMenuLoader
								source={source}
								parentId={parentId}
								isCreateWhiteboardEnabled={isCreateWhiteboardEnabled}
								isCreateDatabaseEnabled={isCreateDatabaseEnabled}
								isCreateSmartLinkEnabled={isCreateSmartLinkEnabled}
								isLivePagesEnabled={isLivePagesEnabled}
								isImportSourcesEnabled={isSurfaceImportExperimentCohort}
								isCreateFolderEnabled={isCreateFolderEnabled && !hideFolder}
								onCloseMenu={onCloseHandler}
								pageStateUpdater={pageStateUpdater}
								onItemClick={onItemClick}
								renderedToParent={shouldRenderToParent}
							/>
						)}
						popupComponent={shouldRenderToParent ? CustomPopupContainer : undefined}
						trigger={(triggerProps) => (
							<TriggerContainer
								onClick={stopEvents}
								onFocus={stopEvents}
								onBlur={stopEvents}
								data-vc="contextual-create-content-popover"
								{...ssrPlaceholderIdProp}
							>
								<span onMouseEnter={preloadCreationMenu} onFocus={preloadCreationMenu}>
									{isTreeItem ? (
										<TreeCreateContentButton
											triggerProps={triggerProps}
											onClick={enforceStorageLimit(onTriggerClick)}
											isSelected={isOpen}
										/>
									) : (
										<ContextualCreateContentButton
											onClick={enforceStorageLimit(onTriggerClick)}
											triggerProps={triggerProps}
											isContextualCreateFocused={isContextualCreateFocused}
											spaceKey={spaceKey}
											rootCreateButtonStyling={rootCreateButtonStyling}
											displayTitle={displayTitle}
										/>
									)}
								</span>
								{!shouldRenderToParent && isOpen && (
									<Blanket
										testId="contextual-create-blanket"
										isTinted={false}
										onBlanketClicked={onCloseHandler}
									/>
								)}
							</TriggerContainer>
						)}
						shouldRenderToParent={shouldRenderToParent}
						testId="contextual-create-popover"
					/>
				</>
			)}
		</Subscribe>
	);
};
